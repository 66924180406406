import React, { useContext, useEffect, useState } from "react";
import RCPagination from "../../utility/RCPagination";
import { getFetch } from "../../utility/CallApi";
import {
  url_getUserList,
  url_newRole,
  DEFAULT_HEADERS,
  METHOD_GET,
  METHOD_POST,
  url_getUserInfo,
  url_deleteUser,
  url_searchQueue,
  getMainCookie,
  url_getBranch,
} from "../../utility/Config";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useRouteMatch,
  Link,
} from "react-router-dom";
import { AlertConfirm, Alert } from "../../utility/AlertBar";
import Swal from "sweetalert2";
import $ from "jquery";
import { Context } from "../../../App";

export default function Reserve() {
  const [usersList, setUsersList] = useState([]);

  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalResult, setTotalResult] = useState(0);
  const [textSearch, setTextSearch] = useState("");
  const [branchList, setBranchList] = useState([]);
  const [branchSelected, setBranchSelected] = useState("");

  const DEFAULT_HEADERS = {
    "Content-Type": "application/json;charset=utf-8",
    Accept: "application/json",
    Authorization: "Bearer " + getMainCookie().token,
  };

  useEffect(() => {
    getQueueList();
    getBranch();
  }, []);

  const getBranch = async () => {
    let data = await getFetch(
      url_getBranch,
      METHOD_GET,
      DEFAULT_HEADERS,
      null,
      null
    );
    let newList = [];
    if (data) {
      data.map((i) => {
        newList.push({ value: i.branch_code, label: i.branch_name });
      });
    }
    setBranchList(newList);
  };

  const getQueueList = async () => {
    let body = {
      searchText: textSearch,
      branchId: branchSelected,
      page: curPage,
      limit: pageSize,
    };
    let data = await getFetch(
      url_searchQueue,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("RESULT_TOTAL:::", data.result);
    setUsersList(data.result);
    setTotalResult(data.total);
  };

  const onChangePage = async (currentPage) => {
    let body = {
      searchText: textSearch,
      branchId: branchSelected,
      page: currentPage,
      limit: pageSize,
    };
    let data = await getFetch(
      url_searchQueue,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    setUsersList(data.result);
    setCurPage(currentPage);
    setTotalResult(data.total);
  };

  const onKeyPress = async (e) => {
    if (e.key === "Enter") {
      getQueueList();
    }
  };

  const onKeyUp = async (val) => {
    let body = {
      searchText: val,
      branchId: branchSelected,
      page: 1,
      limit: pageSize,
    };
    let data = await getFetch(
      url_searchQueue,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    setUsersList(data.result);
    setTotalResult(data.total);
  };

  const onChangeBranch = async (code) => {
    await setBranchSelected(code);
    let body = {
      searchText: textSearch,
      branchId: code,
      page: 1,
      limit: pageSize,
    };
    let data = await getFetch(
      url_searchQueue,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("RESULT_TOTAL:::", data.result);
    setUsersList(data.result);
    setTotalResult(data.total);
  };

  const concatQueDate = (dateFrom, dateTo) => {

    let newDateFrom = ""
    let newTimeFrom = ""
    if(dateFrom){

      let date = dateFrom.split('T')[0]
      let time = dateFrom.split('T')[1]

      let newDate = date.split('-')
      newDateFrom = newDate[2]+"/"+newDate[1]+"/"+newDate[0]
      newTimeFrom = time.slice(0, 5)
    }

    let newDateTo = ""
    let newTimeTo = ""
    if(dateTo){

      let date = dateTo.split('T')[0]
      let time = dateTo.split('T')[1]

      let newDate = date.split('-')
      newDateTo = newDate[2]+"/"+newDate[1]+"/"+newDate[0]
      newTimeTo = time.slice(0, 5)
    }

    if(newDateTo === ""){
      return newDateFrom+' '+newTimeFrom
    }
    else{
      return newDateFrom+' '+newTimeFrom + " - "+newDateTo+' '+newTimeTo
    }
    
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          <h4 style={{ fontSize: "20px" }}>รายการจองคิว Online</h4>
        </div>
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol
              className="breadcrumb"
              style={{
                padding: "0px",
                backgroundColor: "#FFF",
                justifyContent: "end",
              }}
            >
              <li className="breadcrumb-item">
                <Link to={`/page/reserve`}>
                  <i className="fa fa-home" aria-hidden="true" /> หน้าหลัก
                </Link>
              </li>
              {/* <li className="breadcrumb-item active" aria-current="page">
                รายการจองคิว Online
              </li> */}
            </ol>
          </nav>
        </div>
      </div>
      <hr style={{ marginTop: "0px" }} />
      <div className="row">
        <div className="col-1 col-md-1">
          <label
            htmlFor="search"
            className="col-form-label d-flex justify-content-end"
          >
            Search :
          </label>
        </div>
        <div className="col-4 col-md-5">
          <input
            type="text"
            className="form-control"
            id="search"
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
            onKeyPress={(e) => onKeyPress(e)}
            onKeyUp={(e) => onKeyUp(e.target.value)}
          />
        </div>
        <div className="col-2 col-md-2">
          <label
            htmlFor="selectRootModule"
            className="col-form-label d-flex justify-content-end"
          >
            สำนักงานประกันสังคม
          </label>
        </div>
        <div className="col-4 col-md-4">
          <select
            id="branch"
            value={branchSelected}
            onChange={(e) => onChangeBranch(e.target.value)}
            // onBlur={(e) => onChangeBranch(e.target.value)}
            className="form-control text-input"
          >
            <option value={""}>ทั้งหมด</option>
            {branchList &&
              branchList.length > 0 &&
              branchList.map((i, key) => (
                <option key={key} value={i.value}>
                  {i.label}
                </option>
              ))}
          </select>
        </div>
      </div>

      <br />
      <div className="table-responsive">
        <table className="table table-striped table-hover  table-sm">
          <thead>
            <tr style={{ textAlign: "center", backgroundColor: "#FDC121" }}>
              <th scope="col">ลำดับ</th>
              <th scope="col">รหัสการจอง</th>
              <th scope="col">วันที่การจอง</th>
              <th scope="col">ชื่่อ - นามสกุล</th>
              <th scope="col">สำนักงานประกันสังคม</th>
              <th scope="col">สถานะ</th>
              {/* <th scope="col" style={{ width: "10%" }}>Re Password</th>
              <th scope="col" style={{ width: "7%" }}>
                Edit
              </th>
              <th scope="col" style={{ width: "7%" }}>
                Del
              </th> */}
            </tr>
          </thead>
          <tbody>
            {usersList &&
              usersList.length > 0 &&
              usersList.map((i, key) => {
                return (
                  <tr
                    key={key}
                    className="align-middle"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    <td scope="row" className="align-middle">
                      {`${(curPage - 1) * pageSize + key + 1}`}
                    </td>
                    <td className="align-middle">{i.que_service_id}</td>
                    <td className="align-middle">
                      {`${concatQueDate(i.que_date_from, i.que_date_to)}`}
                    </td>
                    <td className="align-middle" style={{ textAlign: "left" }}>
                      {i.full_name}
                    </td>
                    <td className="align-middle" style={{ textAlign: "left" }}>
                      {i.branch_name}
                    </td>
                    <td className="align-middle">{i.status}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <RCPagination
        pageSize={pageSize}
        // onChange={onChangePage}
        current={curPage}
        total={totalResult}
        onSelect={onChangePage}
        // onShowSizeChange={this.onPageSizeChange.bind(this)}
      />
    </div>
  );
}
