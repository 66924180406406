import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../App";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useRouteMatch,
  Link,
} from "react-router-dom";
import $ from "jquery";
import { getMainCookie } from "../../components/utility/Config";

export default function Sidebar() {
  const {
    sideBarActive,
    setSideBarActive,
    selectMenu,
    setSelectMenu,
    contextUserInfo,
    setContextUserInfo,
  } = useContext(Context);
  const { url, path } = useRouteMatch();
  const [userInfo, setUserInfo] = useState(getMainCookie().userInfo);
  const [userPermission, setUserPermission] = useState(
    getMainCookie().userInfo.permissions
  );

  useEffect(async () => {
    if (getMainCookie()) {
      await getMainCookie().userInfo;
      await setUserPermission(getMainCookie().userInfo.permissions);
    }

    $("#consent").removeClass("active");
    $("#member").removeClass("active");
    $("#reserve").removeClass("active");
    $("#dashboard").removeClass("active");
    
    let pathname = window.location.hash;
    if (pathname) {
      let path = pathname.split("/");

      await $("#" + path[2]).addClass("active");

    }
  }, [window.location.hash]);

  const selectedMenu = (e, id, module_id) => {
    e.preventDefault();

    $("#consent").removeClass("active");
    $("#member").removeClass("active");
    $("#reserve").removeClass("active");
    $("#dashboard").removeClass("active");
    
    $("#" + id).addClass("active");

    // let elms = [];
    // let newModule = [];
    // for (let root of userPermission) {
    //   for (let module of root.modules) {
    //     newModule.push(module);
    //   }
    // }
    // for (let module of newModule) {
    //   elms.push(
    //     document
    //       .getElementById("moduleControl" + module.module_id)
    //       .getElementsByTagName("li")
    //   );
    // }

    // for (let module of newModule) {
    //   if (module_id === module.module_id) {
    //     $("#adminManage" + module.module_id).attr("aria-expanded", "true");
    //   } else {
    //     $("#moduleControl" + module.module_id).addClass("collapsed");
    //     $("#moduleControl" + module.module_id).removeClass("show");
    //     $("#adminManage" + module.module_id).attr("aria-expanded", "false");
    //   }
    // }

    // if (elms) {
    //   for (let modules of elms) {
    //     $("#adminManage" + modules.module_id).attr("aria-expanded", "false");
    //     for (let item of modules) {
    //       $("#" + item.id).removeClass("active");
    //     }
    //   }
    // }
    // $("#" + id).addClass("active");
    // setSelectMenu(id);
  };

  const onClearSelectedMenu = async () => {

    $("#consent").removeClass("active");
    $("#member").removeClass("active");
    $("#reserve").removeClass("active");
    $("#dashboard").addClass("active");

  };

  return (
    <aside
      className="main-sidebar"
      style={{
        width: sideBarActive ? "250px" : "80px",
        // height: dynamicHeight+'px'
      }}
    >
      <nav id="sidebar" className={sideBarActive ? "" : "active"}>
        <div className="sidebar-header">
          <h3>
            <Link
              to={`${path}/reserve`}
              onClick={() => onClearSelectedMenu()}
            >
              ระบบสนับสุนนการให้บริการ
            </Link>
          </h3>
          {/* <strong>BS</strong> */}
          <strong>
            <img
              src="/img/sso_logo.png"
              alt=""
              style={{
                // borderRadius: "50%",
                // width: "50%",
                // verticalAlign: "top",
                borderRadius: "10%",
                width: "60%",
                verticalAlign: "initial",
              }}
            />
          </strong>
        </div>
        <ul className="list-unstyled components">
          {/* ADMIN MANAGEMENT */}
          <li className="headerMenu">ADMIN MANAGEMENT</li>
          {/* <li>
            <a
              href="#moduleControl"
              data-toggle="collapse"
              aria-expanded="false"
              className="dropdown-toggle"
              id="adminManage"
            >
              <i className="fa fa-laptop" aria-hidden="true" />
              MODULE CONTORL
            </a>
            <ul className="collapse list-unstyled" id="moduleControl">
              <li id="module" onClick={(e) => selectedMenu(e, "module")}>
                <Link to={`${path}/module`} className="nav-link">
                  <i className="fa fa-circle-o" aria-hidden="true" />
                  MODULE
                </Link>
              </li>
              <li id="pages" onClick={(e) => selectedMenu(e, "pages")}>
                <Link to={`${path}/pages`} className="nav-link">
                  <i className="fa fa-circle-o" aria-hidden="true" />
                  PAGES
                </Link>
              </li>
              <li id="roles" onClick={(e) => selectedMenu(e, "roles")}>
                <Link to={`${path}/roles`} className="nav-link">
                  <i className="fa fa-circle-o" aria-hidden="true" />
                  จัดการสิทธิ์ผู้ใช้งาน
                </Link>
              </li>
              <li id="users" onClick={(e) => selectedMenu(e, "users")}>
                <Link to={`${path}/users`} className="nav-link">
                  <i className="fa fa-circle-o" aria-hidden="true" />
                  ผู้ใช้งาน
                </Link>
              </li>
            </ul>
          </li> */}
          <li id="consent" onClick={(e) => selectedMenu(e, "consent")}>
            <Link to={`${path}/consent`} className="nav-link">
              <i className="fa fa-check-square" aria-hidden="true" />
              Consent
            </Link>
          </li>
          <li id="member" onClick={(e) => selectedMenu(e, "member")}>
            <Link to={`${path}/member`} className="nav-link">
              <i className="fa fa-users" aria-hidden="true" />
              สมาชิก(ผู้รับบริการ)
            </Link>
          </li>
          <li id="reserve" onClick={(e) => selectedMenu(e, "reserve")}>
            <Link to={`${path}/reserve`} className="nav-link">
              <i className="fa fa-calendar" aria-hidden="true" />
              จองคิว Online
            </Link>
          </li>

          {/* MAIN MANAGEMENT */}
          {/* <li className="headerMenu">MAIN NAVIGATION</li>
          <li id="dashboard" onClick={(e) => selectedMenu(e, "dashboard")}>
            <Link to={`${path}/dashboard`} className="nav-link">
              <i className="fa fa-bar-chart" aria-hidden="true" />
              Dashboard
            </Link>
          </li> */}
        </ul>
      </nav>
    </aside>
  );
}
