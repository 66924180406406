import React, { useEffect, useState } from "react";
import RCPagination from "../../utility/RCPagination";
import FormConsent from "./FormConsent";
import { getFetch } from "../../utility/CallApi";
import {
  url_getConsentList,
  url_deleteConsent,
  DEFAULT_HEADERS,
  METHOD_GET,
  METHOD_POST,
  url_getConsentDataById,
  getMainCookie,
  url_getConsentById,
  METHOD_DELETE,
} from "../../utility/Config";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useRouteMatch,
  Link,
} from "react-router-dom";
import { AlertConfirm, Alert } from "../../utility/AlertBar";
import Swal from "sweetalert2";
import $ from "jquery";
import FormPersonalData from "./FormPersonalData";
import LoadingSpinner from "../../utility/LoadingSpinner";

export default function Consent() {
  const { url, path } = useRouteMatch();
  const { name } = useParams();

  const [openPopupAddForm, setOpenPopupAddForm] = useState(false);
  const [openPopupUpdateForm, setOpenPopupUpdateForm] = useState(false);
  const [openModalPersonalData, setOpenModalPersonalData] = useState(false);
  

  const [consentList, setConsentList] = useState([]);

  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalResult, setTotalResult] = useState(0);
  const [textSearch, setTextSearch] = useState("");
  const [objEditConsent, setObjEditConsent] = useState(null);
  const [objEditPersonalData, setObjEditPersonalData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const DEFAULT_HEADERS = {
    "Content-Type": "application/json;charset=utf-8",
    Accept: "application/json",
    Authorization: "Bearer " + getMainCookie().token,
  }

  useEffect(() => {
    getConsentList();
  }, []);

  const getConsentList = async () => {
    
    let data = await getFetch(
      url_getConsentList,
      METHOD_GET,
      DEFAULT_HEADERS,
      null,
      null
    );
    setConsentList(data.data);
    // setTotalResult(data.RESULT_TOTAL);
  };

  // const onChangePage = async (currentPage) => {
  //   let body = {
  //     filter: textSearch,
  //     orderField: "module_id",
  //     orderType: "desc",
  //     pageNo: currentPage,
  //     pageSize: pageSize,
  //   };
  //   let data = await getFetch(
  //     url_getConsentList,
  //     METHOD_POST,
  //     DEFAULT_HEADERS,
  //     body,
  //     null
  //   );
  //   setConsentList(data.RESULT_DATA);
  //   setCurPage(currentPage);
  //   setTotalResult(data.RESULT_TOTAL);
  // };

  const onDeleteConsent = async (e, i) => {
    e.preventDefault();

    AlertConfirm("ต้องการลบ Consent: " + i.consent_name+" \n  ใช่หรือไม่?").then(async (isOk) => {
      if (isOk) {
        await getFetch(
          url_deleteConsent+`${"/"+i.consent_id}`,
          METHOD_DELETE,
          DEFAULT_HEADERS,
          null,
          null
        ).then(async (res) => {
          if (res && res.data != 0) {
            Alert("ลบ Consent", "ลบ Consent เรียบร้อยแล้ว", "success");
            await getConsentList();
          }else if(res && res.data == 0){
            Alert("เกิดข้อผิดพลาด", "","error");
          }
        });
      }
    });
  };

  const onKeyPress = async (e) => {
    if (e.key === "Enter") {
      getConsentList();
    }
  };

  const onKeyUp = async (val) => {
    let data = await getFetch(
      url_getConsentList,
      METHOD_GET,
      DEFAULT_HEADERS,
      null,
      null
    );
    console.log("RESULT_TOTAL:::", data);
    setConsentList(data);
    setTotalResult(data);
  };

  const onClosePopup = async () => {
    console.log("onClosePopup:::");
    await window.$("#modalFormConsent").modal("hide");
    await window.$("#modalPersonalData").modal("hide");
    await setOpenPopupAddForm(false);
    await setOpenPopupUpdateForm(false);

    await setObjEditPersonalData(false);
    await setOpenModalPersonalData(false);

    await getConsentList();
  };

  const onEditConsent = async (id) => {
    let data = await getConsentInfo(id);
    await setObjEditConsent(data);
    await setOpenPopupUpdateForm(true);
  };

  const getConsentInfo = async (id) => {
    let data = await getFetch(
      url_getConsentById+`${"/"+id}`,
      METHOD_GET,
      DEFAULT_HEADERS,
      null,
      null
    );
    if (data) {
      return data.data;
    } else {
      return;
    }
  };

  const onEditPersonalData = async (id) => {
    let data = await getPersonalData(id);
    // console.log(data);
    // let newData = {...data,consent_id: id}
    await setObjEditPersonalData(data);
    await setOpenModalPersonalData(true)
  };

  const getPersonalData = async (id) => {
    let data = await getFetch(
      url_getConsentDataById+`${"/"+id}`,
      METHOD_GET,
      DEFAULT_HEADERS,
      null,
      null
    );
    
    let newData = {}
    if (data && data.data.length > 0) {
      newData = {...data,consent_id: id}
    } else {
      console.log('else');
      newData = {data:[],consent_id: id}
    }
    return newData;

  };

  return (
    <>
      <div className="row">
        <div className="col">
          <h4 style={{fontSize:'20px'}}>ขอความยินยอม</h4>
        </div>
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb" style={{padding: '0px',backgroundColor: '#FFF',justifyContent:'end'}}>
              <li className="breadcrumb-item">
                <Link to={`/page/reserve`}>
                <i className="fa fa-home" aria-hidden="true"/>{" "}หน้าหลัก
                </Link>
                
              </li>
              <li className="breadcrumb-item active" aria-current="page">
              ขอความยินยอม
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <hr style={{marginTop:'0px'}} />
      <div className="row">
        {/* <div className="col-1 col-md-1">
          <label
            htmlFor="search"
            className="col-form-label d-flex justify-content-end"
          >
            Search :
          </label>
        </div>
        <div className="col-5 col-md-5">
          <input
            type="text"
            className="form-control"
            id="search"
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
            onKeyPress={(e) => onKeyPress(e)}
            onKeyUp={(e) => onKeyUp(e.target.value)}
          />
        </div> */}
        <div className="offset-6 col-6 col-md-6  d-flex justify-content-end">
          <button
            id="#modalFormConsent"
            type="button"
            className="d-flex btn btn-success align-items-center"
            data-toggle="modal"
            data-target="#modalFormConsent"
            data-backdrop="static"
            data-keyboard="false"
            onClick={() => setOpenPopupAddForm(true)}
          >
            <i
              className="fa fa-plus"
              aria-hidden="true"
              style={{ paddingRight: "5px" }}
            />
            Consent
          </button>
          {/* <button
            id="#modalPersonalData"
            type="button"
            className="d-flex btn btn-success align-items-center"
            data-toggle="modal"
            data-target="#modalPersonalData"
            data-backdrop="static"
            data-keyboard="false"
            onClick={() => setOpenModalPersonalData(true)}
          >
            <i
              className="fa fa-plus"
              aria-hidden="true"
              style={{ paddingRight: "5px" }}
            />
            PDATA
          </button> */}
        </div>
      </div>
      <div
        className="modal fade"
        id="modalFormConsent"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalFormConsent"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            {openPopupAddForm && <FormConsent onClosePopup={onClosePopup} />}
            {openPopupUpdateForm && (
              <FormConsent
                onClosePopup={onClosePopup}
                objEditConsent={objEditConsent}
                openPopupUpdateForm={openPopupUpdateForm}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalPersonalData"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalPersonalData"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            {openModalPersonalData && (
              <FormPersonalData
                onClosePopup={onClosePopup}
                objEditPersonalData={objEditPersonalData}
                openModalPersonalData={openModalPersonalData}
              />
            )}
          </div>
        </div>
      </div>
      <br />
      <div className="table-responsive">
        <table className="table table-striped table-hover  table-sm">
          <thead>
            <tr style={{ textAlign: "center",backgroundColor:'#FDC121' }}>
              <th scope="col" style={{ width: "7%" }}>ลำดับ</th>
              <th scope="col">รายการ</th>					
              <th scope="col" style={{ width: "15%" }}>จัดการข้อมูลส่วนบุคคล</th>
              <th scope="col" style={{ width: "8%" }}>สถานะ</th>
              <th scope="col" style={{ width: "7%" }}>
              แก้ไข
              </th>
              <th scope="col" style={{ width: "7%" }}>
              ลบ
              </th>
            </tr>
          </thead>
          <tbody>
            {consentList &&
              consentList.length > 0 &&
              consentList.map((i, key) => {
                return (
                  <tr
                    key={key}
                    className="align-middle"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    <td scope="row" className="align-middle">
                      {`${key + 1}`}
                    </td>
                    <td className="align-middle" style={{textAlign: "left"}}>{i.consent_name}</td>
                    <td className="align-middle">
                    <button
                        className="btn btn-link"
                        // onClick={(e) => onEditConsent(e, i)}
                        id="#modalPersonalData"
                        type="button"
                        data-toggle="modal"
                        data-target="#modalPersonalData"
                        data-backdrop="static"
                        data-keyboard="false"
                        onClick={(e) => onEditPersonalData(i.consent_id)}
                      >
                        <i
                          className="fa fa-user-secret"
                          aria-hidden="true"
                          style={{
                            fontSize: "1.4rem",
                            verticalAlign: "middle",
                            color:"#3c8dbc"
                          }}
                        />
                      </button>
                    </td>
                    <td className="align-middle">
                      {i.is_active === "Y" ? "ใช้งาน" : "ไม่ใช้งาน"}
                    </td>
                    <td className="align-middle">
                      <button
                        className="btn btn-link"
                        // onClick={(e) => onEditConsent(e, i)}
                        id="#modalFormConsent"
                        type="button"
                        data-toggle="modal"
                        data-target="#modalFormConsent"
                        data-backdrop="static"
                        data-keyboard="false"
                        onClick={(e) => onEditConsent(i.consent_id)}
                      >
                        <i
                          className="fa fa-pencil-square-o"
                          aria-hidden="true"
                          style={{
                            fontSize: "1.4rem",
                            verticalAlign: "middle",
                          }}
                        />
                      </button>
                    </td>
                    <td className="align-middle">
                      <button
                        className="btn btn-link"
                        onClick={(e) => onDeleteConsent(e, i)}
                      >
                        <i
                          className="fa fa-trash-o"
                          aria-hidden="true"
                          style={{
                            color: "red",
                            fontSize: "1.4rem",
                            verticalAlign: "middle",
                          }}
                        />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {/* <RCPagination
        pageSize={pageSize}
        // onChange={onChangePage}
        current={curPage}
        total={totalResult}
        onSelect={onChangePage}
        // onShowSizeChange={this.onPageSizeChange.bind(this)}
      /> */}
    </>
  );
}
