import React, { useEffect, useState, useRef } from "react";
import "./css/FormConsent.css";
import { Formik } from "formik";
import * as Yup from "yup";
import $ from "jquery";
import {
  url_getModuleList,
  url_deleteModule,
  DEFAULT_HEADERS,
  METHOD_GET,
  METHOD_POST,
  url_addConsentData,
  url_updateModule,
  PERSONAL_DATA,
  url_getConsentDataById,
  METHOD_DELETE,
  url_deleteConsentData,
  getMainCookie,
} from "../../utility/Config";
import { getFetch } from "../../utility/CallApi";
import { AlertCommit, AlertConfirm, Alert } from "../../utility/AlertBar";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function FormPersonalData(props) {
  const [objEdit, setObjEdit] = useState(props.objEditPersonalData);
  const [value, setValue] = useState("");
  const [mem_name, setMem_name] = useState("");
  const [mem_last, setMem_last] = useState("");
  const [mem_idcard, setMem_idcard] = useState("");
  const [email, setEmail] = useState("");
  const [tel_no, setTel_no] = useState("");
  const [mem_dob, setMem_dob] = useState("");
  const [checked, setChecked] = useState([]);

  const formikRef = useRef();

  const DEFAULT_HEADERS = {
    "Content-Type": "application/json;charset=utf-8",
    Accept: "application/json",
    Authorization: "Bearer " + getMainCookie().token,
  };

  useEffect(() => {
    setObjEdit(props.objEditPersonalData);
    return () => {
      setObjEdit(null);
      setChecked([]);
    };
  }, [props.objEditPersonalData]);

  const onSavePersonalData = async (e) => {
    console.log("props:::", props);

    let body = {
      consent_id: objEdit.consent_id,
      consent_data_name: e.consent_data_name,
      hide_list: checked,
    };
    console.log("body::::", body);
    let data = await getFetch(
      url_addConsentData,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("data.RESULT_STATUS:::", data);
    if (data) {
      await AlertCommit(true, "บันทึกเรียบร้อยแล้ว");
      let data = await getConsentInfo(objEdit.consent_id);
      let newData = {};
      newData = { data: data, consent_id: objEdit.consent_id };
      await setObjEdit(newData);
      await clearInput();
      await setChecked([]);
    } else {
      await AlertCommit(false, "บันทึกไม่สำเร็จ");
      await clearInput();
      await setChecked([]);
    }
    // await props.onClosePopup();
  };

  const getConsentInfo = async (id) => {
    let data = await getFetch(
      url_getConsentDataById + `${"/" + id}`,
      METHOD_GET,
      DEFAULT_HEADERS,
      null,
      null
    );
    if (data && data.data.length > 0) {
      return data.data;
    } else {
      return;
    }
  };

  const onDeleteConsent = async (e, i) => {
    e.preventDefault();

    AlertConfirm(
      "ต้องการลบรายการ: " + i.consent_data_name + " \n  ใช่หรือไม่?"
    ).then(async (isOk) => {
      if (isOk) {
        await getFetch(
          url_deleteConsentData + `${"/" + i.consent_data_id}`,
          METHOD_DELETE,
          DEFAULT_HEADERS,
          null,
          null
        ).then(async (res) => {
          if (res.data) {
            Alert("ลบการจัดการข้อมูลส่วนบุคคล", "เรียบร้อยแล้ว", "success");
            let data = await getConsentInfo(objEdit.consent_id);
            let newData = {};
            newData = { data: data, consent_id: objEdit.consent_id };
            await setObjEdit(newData);
            await clearInput();
            await setChecked([]);
            await formikRef.current?.resetForm();
          }
        });
      }
    });
  };

  const onClosePopup = async () => {
    await props.onClosePopup();
  };

  const genHideList = (hideList) => {
    
    if (hideList && hideList.length > 0) {
      let list = hideList.split(",");

      let newStr = "";
      if (list && list.length > 0) {
        list.map((i) => {
          newStr = "- " + PERSONAL_DATA[i] + "\n" + newStr;
        });
        return newStr;
      } else {
        return;
      }
    } else {
      return;
    }
  };

  const setHintList = (event) => {
    let updatedList = [...checked];

    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }

    setChecked(updatedList);
  };

  const clearInput = async () => {
    $(".ckAll").prop("checked", false);
  };

  return (
    <Formik
      initialValues={{
        consent_data_name: "",
      }}
      onSubmit={async (values, onSubmitProps) => {
        await onSavePersonalData(values);
        await onSubmitProps.resetForm();
      }}
      validationSchema={Yup.object().shape({
        consent_data_name: Yup.string().required("โปรดใส่ข้อมูล"),
      })}
      innerRef={formikRef}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;

        return (
          <form onSubmit={handleSubmit}>
            <div className="modal-header">
              <h5 className="modal-title" id="manageModule">
                <b>จัดการข้อมูลส่วนบุคคล</b>
              </h5>
              <button
                id="btnClose"
                type="button"
                className="close"
                // data-dismiss="modal"
                // aria-label="Close"
                // disabled={!dirty || isSubmitting}
                onClick={() => onClosePopup()}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card">
                <div className="card-body">
                  <h6 className="card-title mb-3">รายละเอียดข้อมูล</h6>
                  <div className="form-group col-12 row">
                    <label
                      htmlFor="consent_data_name"
                      className="card-title d-flex justify-content-start"
                    >
                      <b>รายการ</b>
                    </label>
                    <input
                      type="text"
                      id="consent_data_name"
                      name="consent_data_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.consent_data_name}
                      className={
                        errors.consent_data_name && touched.consent_data_name
                          ? "col-12 text-input error"
                          : "col-12 text-input"
                      }
                      placeholder="รายการ"
                    />
                    {/* {errors.consent_data_name && touched.consent_data_name && (
                      <div className="input-feedback">
                        {errors.consent_data_name}
                      </div>
                    )} */}
                  </div>
                  <div className="form-group col-12 row">
                    <label
                      htmlFor="moduleCode"
                      className="card-title d-flex justify-content-start"
                    >
                      <b>ปกปิดข้อมูลส่วนบุคคล</b>
                    </label>

                    <div className="col-12">
                      <div className="form-check form-check-inline col-4 mr-0">
                        <input
                          className="ckAll form-check-input"
                          type="checkbox"
                          name=""
                          id="mem_name"
                          value="mem_name"
                          style={{ width: "25px" }}
                          onChange={(e) => setHintList(e)}
                        />
                        <label className="form-check-label" for="mem_name">
                          ชื่อ
                        </label>
                      </div>
                      <div className="form-check form-check-inline col-4 mr-0">
                        <input
                          className="ckAll form-check-input"
                          type="checkbox"
                          id="mem_last"
                          value="mem_last"
                          style={{ width: "25px" }}
                          onChange={(e) => setHintList(e)}
                        />
                        <label className="form-check-label" for="mem_last">
                          นามสกุล
                        </label>
                      </div>
                      <div className="form-check form-check-inline col-4 mr-0">
                        <input
                          className="ckAll form-check-input"
                          type="checkbox"
                          id="mem_idcard"
                          value="mem_idcard"
                          style={{ width: "25px" }}
                          onChange={(e) => setHintList(e)}
                        />
                        <label className="form-check-label" for="mem_idcard">
                          เลขที่บัตรประชาชน
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-check form-check-inline col-4 mr-0">
                        <input
                          className="ckAll form-check-input"
                          type="checkbox"
                          id="mem_dob"
                          value="mem_dob"
                          style={{ width: "25px" }}
                          onChange={(e) => setHintList(e)}
                        />
                        <label className="form-check-label" for="mem_dob">
                          วันเดือนปีเกิด
                        </label>
                      </div>
                      <div className="form-check form-check-inline col-4 mr-0">
                        <input
                          className="ckAll form-check-input"
                          type="checkbox"
                          id="email"
                          value="email"
                          style={{ width: "25px" }}
                          onChange={(e) => setHintList(e)}
                        />
                        <label className="form-check-label" for="email">
                          Email
                        </label>
                      </div>
                      <div className="form-check form-check-inline col-4 mr-0">
                        <input
                          className="ckAll form-check-input"
                          type="checkbox"
                          id="tel_no"
                          value="tel_no"
                          style={{ width: "25px" }}
                          onChange={(e) => setHintList(e)}
                        />
                        <label className="form-check-label" for="tel_no">
                          เบอร์โทร
                        </label>
                      </div>
                    </div>
                    {/* {errors.moduleCode && touched.moduleCode && (
                  <div className="input-feedback">{errors.moduleCode}</div>
                )} */}
                  </div>
                  <hr />
                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-primary mr-2"
                      disabled={isSubmitting}
                    >
                      บันทึก
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      // onClick={handleReset}
                      onClick={() => onClosePopup()}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              </div>
              <div className="card mt-2">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover  table-sm">
                      <thead>
                        <tr
                          style={{
                            textAlign: "center",
                            backgroundColor: "#FDC121",
                          }}
                        >
                          <th scope="col">ลำดับ</th>
                          <th scope="col">รายการ</th>
                          <th scope="col" style={{ width: "25%" }}>
                            ปกปิดข้อมูลส่วนบุคคล
                          </th>
                          <th scope="col">ลบ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {objEdit &&
                          objEdit.data &&
                          objEdit.data.length > 0 &&
                          objEdit.data.map((i, key) => {
                            return (
                              <tr
                                key={key}
                                className="align-middle"
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                <td scope="row" className="align-top">
                                  {`${key + 1}`}
                                </td>
                                <td
                                  className="align-top"
                                  style={{ textAlign: "left" }}
                                >
                                  {i.consent_data_name}
                                </td>
                                <td
                                  className="align-middle"
                                  style={{
                                    whiteSpace: "pre",
                                    textAlign: "left",
                                  }}
                                >
                                  {genHideList(i.hide_list)}
                                </td>
                                <td className="align-top">
                                  <button
                                    className="btn btn-link"
                                    onClick={(e) => onDeleteConsent(e, i)}
                                  >
                                    <i
                                      className="fa fa-trash-o"
                                      aria-hidden="true"
                                      style={{
                                        color: "red",
                                        fontSize: "1.4rem",
                                        verticalAlign: "middle",
                                      }}
                                    />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* </div>
              </div>
            </div> */}
          </form>
        );
      }}
    </Formik>
  );
}
