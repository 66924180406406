import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useRouteMatch,
  Link,
} from "react-router-dom";

import "chart.js/auto";
import { Chart } from "react-chartjs-2";

import './css/Dashboard.css'

export default function Dashboard() {
  return (
    <>
      <div className="row">
        <div className="col">
          <h4 style={{ fontSize: "20px" }}>Dashboard</h4>
        </div>
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol
              className="breadcrumb"
              style={{
                padding: "0px",
                backgroundColor: "#FFF",
                justifyContent: "end",
              }}
            >
              <li className="breadcrumb-item">
                <Link to={`/page/dashboard`}>
                  <i className="fa fa-home" aria-hidden="true" /> Dashboard
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <hr style={{ marginTop: "0px" }} />
      <div className="row card-deck">
        <div className="col-lg-3 col-md-6 mb-3">
          <div
            className="card"
            style={{ backgroundColor: "#00b4d8", color: "#FFF" }}
          >
            <div className="card-body">
              <h3 className="card-title">0</h3>
              <p className="card-text">ผู้มารับบริการ</p>
              <i className="fa fa-wheelchair iconCard" aria-hidden="true"/>
            </div>
            <div className="card-footer">
              <p className="card-text font-weight-bold text-center">งานสิทธิประโยชน์ทดแทน</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-3">
          <div
            className="card"
            style={{ backgroundColor: "#0096c7", color: "#FFF" }}
          >
            <div className="card-body">
              <h3 className="card-title">0</h3>
              <p className="card-text">ผู้มารับบริการ</p>
              <i className="fa fa-bitcoin iconCard" aria-hidden="true"/>
            </div>
            <div className="card-footer">
              <p className="card-text font-weight-bold text-center">งานการเงินและบัญชี</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-3">
          <div
            className="card"
            style={{ backgroundColor: "#0077b6d1", color: "#FFF" }}
          >
            <div className="card-body">
              <h3 className="card-title">0</h3>
              <p className="card-text">ผู้มารับบริการ</p>
              <i className="fa fa-suitcase iconCard" aria-hidden="true"/>
            </div>
            <div className="card-footer">
              <p className="card-text font-weight-bold text-center">งานเงินสมทบ</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-3">
          <div
            className="card"
            style={{ backgroundColor: "#6480a8c4", color: "#FFF" }}
          >
            <div className="card-body">
              <h3 className="card-title">0</h3>
              <p className="card-text">ผู้มารับบริการ</p>
              <i className="fa fa-user iconCard" aria-hidden="true"/>
            </div>
            <div className="card-footer">
              <p className="card-text font-weight-bold text-center">งานทะเบียน</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6">
          <div className="card">
            <div className="card-body">
              <Chart
                type="bar"
                width={100}
                height={50}
                data={{
                  labels: [
                    "Red",
                    "Blue",
                    "Yellow",
                    "Green",
                    "Purple",
                    "Orange",
                  ],
                  datasets: [
                    {
                      label: "# of Votes",
                      data: [12, 19, 3, 5, 2, 3],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(255, 159, 64, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 159, 64, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card">
            <div className="card-body">
              <Chart
                type="line"
                width={100}
                height={50}
                data={{
                  labels: [
                    "Red",
                    "Blue",
                    "Yellow",
                    "Green",
                    "Purple",
                    "Orange",
                  ],
                  datasets: [
                    {
                      label: "# of Votes",
                      data: [12, 19, 3, 5, 2, 3],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(255, 159, 64, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 159, 64, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
