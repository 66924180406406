import React, { useContext, useEffect, useState } from "react";
import RCPagination from "../../utility/RCPagination";
import { getFetch } from "../../utility/CallApi";
import {
  url_getUsers,
  url_newRole,
  DEFAULT_HEADERS,
  METHOD_GET,
  METHOD_POST,
  url_getUserInfo,
  url_deleteUser,
  getMainCookie,
} from "../../utility/Config";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useRouteMatch,
  Link,
} from "react-router-dom";
import { AlertConfirm, Alert } from "../../utility/AlertBar";
import Swal from "sweetalert2";
import $ from "jquery";
import { Context } from "../../../App";
import axios from "axios";

export default function Member() {
  const { contextUserInfo, setContextUserInfo } = useContext(Context);

  const { url, path } = useRouteMatch();
  const { name } = useParams();
  const [openPopupAddForm, setOpenPopupAddForm] = useState(false);
  const [openPopupUpdateForm, setOpenPopupUpdateForm] = useState(false);
  const [openPopupResetPasswordForm, setOpenPopupResetPasswordForm] =
    useState(false);

  const [usersList, setUsersList] = useState([]);

  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalResult, setTotalResult] = useState(0);
  const [textSearch, setTextSearch] = useState("");
  const [objEditUsers, setObjEditUsers] = useState(null);
  const [objResetPass, setobjResetPass] = useState(null);

  const DEFAULT_HEADERS = {
    "Content-Type": "application/json;charset=utf-8",
    Accept: "application/json",
    Authorization: "Bearer " + getMainCookie().token,
  };

  useEffect(() => {
    getUsersList();
  }, []);

  const getUsersList = async () => {
    let data = await getFetch(
      url_getUsers +
        "?page=" +
        curPage +
        "&limit=" +
        pageSize +
        "&searchText=" +
        textSearch,
      METHOD_GET,
      DEFAULT_HEADERS,
      null,
      null
    );
    console.log("RESULT_TOTAL:::", data.result);
    setUsersList(data.result);
    setTotalResult(data.total);
    //     await axios.get(url_getUsers+'?page=1&limit=5&searchText=').then((res)=> {
    // console.log(res);
    // })
  };

  const onChangePage = async (currentPage) => {
    let data = await getFetch(
      url_getUsers +
        "?page=" +
        currentPage +
        "&limit=" +
        pageSize +
        "&searchText=" +
        textSearch,
      METHOD_GET,
      DEFAULT_HEADERS,
      null,
      null
    );
    setUsersList(data.result);
    setCurPage(currentPage);
    setTotalResult(data.total);
  };

  const onDeleteModule = async (e, i) => {
    e.preventDefault();

    AlertConfirm("ต้องการลบ Users : " + i.user_login + " \n ใช่หรือไม่?").then(
      async (isOk) => {
        if (isOk) {
          let data = {
            delete_user_id: i.user_id,
          };
          await getFetch(
            url_deleteUser,
            METHOD_POST,
            DEFAULT_HEADERS,
            data,
            null
          ).then(async (res) => {
            if (res.RESULT_STATUS === "000") {
              Alert("ลบ Users", "ลบ Users เรียบร้อยแล้ว", "success");
              await getUsersList();
            }
          });
        }
      }
    );
  };

  const onKeyPress = async (e) => {
    console.log("XXX");
    if (e.key === "Enter") {
      getUsersList();
    }
  };

  const onKeyUp = async (val) => {
    let data = await getFetch(
      url_getUsers +
        "?page=" +
        curPage +
        "&limit=" +
        pageSize +
        "&searchText=" +
        val,
      METHOD_GET,
      DEFAULT_HEADERS,
      null,
      null
    );
    console.log("RESULT_TOTAL:::", data.result);
    setUsersList(data.result);
    setTotalResult(data.total);
  };

  const onClosePopup = async () => {
    console.log("onClosePopup:::");
    await window.$("#modalFormUsers").modal("hide");
    await window.$("#modalFormResetPassword").modal("hide");
    await setOpenPopupAddForm(false);
    await setOpenPopupUpdateForm(false);
    await setOpenPopupResetPasswordForm(false);
    await getUsersList();
  };

  const onEditUser = async (id) => {
    let data = await getUsersInfo(id);
    if (data) {
      let newRole = data.role_list.split(",");
      let newRoleList = [];
      newRole.map((i) => newRoleList.push(Number(i)));
      data["role_list"] = newRoleList;
      await setObjEditUsers(data);
      await setOpenPopupUpdateForm(true);
    }
  };

  const getUsersInfo = async (id) => {
    let body = {
      get_user_id: id,
    };
    let data = await getFetch(
      url_getUserInfo,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    if (data.RESULT_DATA.length > 0) {
      return data.RESULT_DATA[0];
    } else {
      return;
    }
  };

  const onResetPassword = async (id) => {
    let data = await getUsersInfo(id);
    if (data) {
      await setobjResetPass(data);
      await setOpenPopupResetPasswordForm(true);
    }
  };

  const formatDob = (dob) => {
    if (dob) {
      if (dob.startsWith("*")) {
        return dob;
      } else {
        let str = dob.split("-");
        return str[2] + "-" + str[1] + "-" + str[0];
      }
    } else {
      return;
    }
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <h4 style={{ fontSize: "20px" }}>สมาชิก (ผู้รับบริการ)</h4>
        </div>
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol
              className="breadcrumb"
              style={{
                padding: "0px",
                backgroundColor: "#FFF",
                justifyContent: "end",
              }}
            >
              <li className="breadcrumb-item">
                <Link to={`/page/reserve`}>
                  <i className="fa fa-home" aria-hidden="true" /> หน้าหลัก
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                สมาชิก (ผู้รับบริการ)
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <hr style={{ marginTop: "0px" }} />
      <div className="row">
        <div className="col-1 col-md-1">
          <label
            htmlFor="search"
            className="col-form-label d-flex justify-content-end"
          >
            Search :
          </label>
        </div>
        <div className="col-5 col-md-5">
          <input
            type="text"
            className="form-control"
            id="search"
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
            onKeyPress={(e) => onKeyPress(e)}
            onKeyUp={(e) => onKeyUp(e.target.value)}
          />
        </div>
      </div>

      <br />
      <div className="table-responsive">
        <table className="table table-striped table-hover  table-sm">
          <thead>
            <tr style={{ textAlign: "center", backgroundColor: "#FDC121" }}>
              <th scope="col">ลำดับ</th>
              <th scope="col">ชื่่อ - นามสกุล</th>
              <th scope="col">เลขที่บัตรประชาชน</th>
              <th scope="col">วันเดือนปีเกิด</th>
              <th scope="col">เบอร์โทร</th>
              <th scope="col">Email</th>
              <th scope="col" style={{ width: "8%" }}>
                สถานะ
              </th>
            </tr>
          </thead>
          <tbody>
            {usersList &&
              usersList.length > 0 &&
              usersList.map((i, key) => {
                return (
                  <tr
                    key={key}
                    className="align-middle"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    <td scope="row" className="align-middle">
                      {`${(curPage - 1) * pageSize + key + 1}`}
                    </td>
                    <td className="align-middle" style={{ textAlign: "left" }}>
                      {i.mem_name + " " + i.mem_last}
                    </td>
                    <td className="align-middle">{i.mem_idcard}</td>
                    <td className="align-middle">{formatDob(i.mem_dob)}</td>
                    <td className="align-middle">{i.tel_no}</td>
                    <td className="align-middle" style={{ textAlign: "left" }}>
                      {i.email}
                    </td>
                    <td className="align-middle">
                      {i.is_active === "Y" ? "ใช้งาน" : "ไม่ใช้งาน"}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <RCPagination
        pageSize={pageSize}
        // onChange={onChangePage}
        current={curPage}
        total={totalResult}
        onSelect={onChangePage}
        // onShowSizeChange={this.onPageSizeChange.bind(this)}
      />
      {/* <div className="form-group col-12 row" style={{paddingRight:'0px'}}>
          <div className="col-6">Showing 1 to 10 of 12 entries</div>
            <nav aria-label="Page navigation example" className="col-6" style={{paddingRight:'0px'}}>
              <ul className="pagination justify-content-end">
                <li className="page-item disabled">
                  <a className="page-link" href="#" tabindex="-1">
                    Previous
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
        </div> */}
    </>
  );
}
