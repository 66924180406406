import axios from "axios";
import { deleteCookie } from "./Config";

const logger = require("./Logger");

export const getFetch = async (url, method, headers, body, timeout) => {
  logger.debug(
    "API/fetch|url:" +
      url +
      "|method=" +
      method +
      "|headers=" +
      JSON.stringify +
      "|body=" +
      body
  );
  let retVal = null;

  try {
    await axios({
      method: method,
      url: url,
      data: body,
      headers: headers,
      timeout: timeout,
      withCredentials: true,
    }).then(
      (res) => {
        if (res) {
          retVal = res.data;
        }
      },
      (error) => {
        if (error.response.status === 401) {
          deleteCookie("sso_queue");
          window.location.href = "/";
        }
        retVal = { error: true, message: error.message };
      }
    );
  } catch (err) {
    logger.error(err);
    // if (err.response) {
    //   logger.error(err.response.data);
    //   logger.error(err.response.status);
    //   logger.error(err.response.headers);

    if (err.response.status === 401) {
      window.location.href = "/login";
    }
    throw err;
  }

  return retVal;
};
