import React, { useEffect, useState, useRef } from "react";
import "./css/FormConsent.css";
import { Formik } from "formik";
import * as Yup from "yup";
import $ from "jquery";
import {
  url_getModuleList,
  url_deleteModule,
  DEFAULT_HEADERS,
  METHOD_GET,
  METHOD_POST,
  url_addConsent,
  url_updateConsent,
  getMainCookie,
  METHOD_PUT,
} from "../../utility/Config";
import { getFetch } from "../../utility/CallApi";
import { AlertCommit } from "../../utility/AlertBar";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

export default function FormConsent(props) {
  const [objEdit, setObjEdit] = useState(props.objEditConsent);
  const [consent_desc, setConsentDesc] = useState("");

  const config = {
    readonly: false,
    height: 300,
  };

  const DEFAULT_HEADERS = {
    "Content-Type": "application/json;charset=utf-8",
    Accept: "application/json",
    Authorization: "Bearer " + getMainCookie().token,
  };

  useEffect(() => {
    setObjEdit(props.objEditConsent);
    setConsentDesc(
      props.objEditConsent ? props.objEditConsent.consent_desc : ""
    );

    return () => {
      setObjEdit(null);
      setConsentDesc("")
    };
  }, [props.objEditConsent]);

  const onSaveConsent = async (e) => {
    console.log("props:::", props);

    let body = {
      consent_name: e.consent_name,
      consent_desc: consent_desc,
      is_active: e.is_active,
    };

    console.log("body::::", body);
    let data = await getFetch(
      url_addConsent,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("data.RESULT_STATUS:::", data);
    if (data) {
      await AlertCommit(true, "บันทึกเรียบร้อยแล้ว");
    } else {
      await AlertCommit(false, "บันทึกไม่สำเร็จ");
    }
    await props.onClosePopup();
  };

  const onUpdateConsent = async (e) => {
    console.log("onUpdateConsent:::", props);

    let body = {
      consent_name: e.consent_name,
      consent_desc: consent_desc,
      is_active: e.is_active,
      consent_id: objEdit.consent_id,
    };

    console.log("body::::", body);
    let data = await getFetch(
      url_updateConsent,
      METHOD_PUT,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("data.RESULT_STATUS:::", data);
    if (data) {
      await AlertCommit(true, "บันทึกเรียบร้อยแล้ว");
    } else {
      await AlertCommit(false, "บันทึกไม่สำเร็จ");
    }
    await props.onClosePopup();
  };

  const onClosePopup = async () => {
    await props.onClosePopup();
  };

  const modules = {
    table: true,
    toolbar: [
      [{ font: [] }, { size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["direction", { align: [] }],
      ["link", "image"],
      ["clean"],
      ["table"],
    ],
  };

  const handleUpdate = (event) => {
    const editorContent = event;
    setConsentDesc(editorContent);
    console.log(editorContent);
  };

  return (
    <Formik
      initialValues={{
        consent_name: objEdit ? objEdit.consent_name : "",
        is_active: objEdit ? objEdit.is_active : "Y",
      }}
      onSubmit={async (values) => {
        console.log("values:::", values);
        if (props.openPopupUpdateForm) {
          await onUpdateConsent(values);
        } else {
          await onSaveConsent(values);
        }
      }}
      validationSchema={Yup.object().shape({
        consent_name: Yup.string().required("โปรดใส่ข้อมูล"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;

        return (
          <form onSubmit={handleSubmit}>
            <div className="modal-header">
              <h5 className="modal-title" id="manageModule">
                <b>จัดการ Consent</b>
              </h5>
              <button
                id="btnClose"
                type="button"
                className="close"
                // data-dismiss="modal"
                // aria-label="Close"
                // disabled={!dirty || isSubmitting}
                onClick={() => onClosePopup()}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group col-12 row">
                <label
                  htmlFor="consent_name"
                  className="col-1 col-form-label d-flex justify-content-start"
                >
                  <b>รายการ</b>
                </label>
                <input
                  type="text"
                  id="consent_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.consent_name}
                  className={
                    errors.consent_name && touched.consent_name
                      ? "col-7 text-input error"
                      : "col-7 text-input"
                  }
                  name="consent_name"
                  placeholder="รายการ"
                />
                {/* {errors.moduleCode && touched.moduleCode && (
                  <div className="input-feedback">{errors.moduleCode}</div>
                )} */}

                <label
                  htmlFor="selectRootModule"
                  className="col-1 col-form-label d-flex justify-content-end"
                >
                  <b>สถานะ</b>
                </label>
                <select
                  id="is_active"
                  value={values.is_active}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.is_active && touched.is_active
                      ? "form-control col-3 text-input error"
                      : "form-control col-3 text-input"
                  }
                >
                  <option value={"Y"}>ใช้งาน</option>
                  <option value={"N"}>ไม่ใช้งาน</option>
                </select>
                {/* {errors.is_active && touched.is_active && (
                  <div className="input-feedback">{errors.is_active}</div>
                )} */}
              </div>
              <div className="form-group col-12 row">
                <label
                  htmlFor="consent_desc"
                  className="col-1 col-form-label d-flex justify-content-start"
                >
                  <b>ข้อความ</b>
                </label>
              </div>
              <div className="col-12">
                <CKEditor
                  // isLayoutReady={false}
                  onReady={(editor) => {
                    // console.log( 'Editor is ready to use!', editor );

                    // Insert the toolbar before the editable area.
                    editor.ui
                      .getEditableElement()
                      .parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                      );

                    // editor = editor;
                  }}
                  onError={(error, { willEditorRestart }) => {
                    // If the editor is restarted, the toolbar element will be created once again.
                    // The `onReady` callback will be called again and the new toolbar will be added.
                    // This is why you need to remove the older toolbar.
                    if (willEditorRestart) {
                      this.editor.ui.view.toolbar.element.remove();
                    }
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setConsentDesc(data ? data : "");
                    // console.log({ event, editor, data });
                  }}
                  editor={DecoupledEditor}
                  data={consent_desc}
                  // config={ /* the editor configuration */ }
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                // onClick={handleReset}
                onClick={() => onClosePopup()}
              >
                ยกเลิก
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                บันทึก
              </button>
            </div>
            {/* </div>
              </div>
            </div> */}
          </form>
        );
      }}
    </Formik>
  );
}
