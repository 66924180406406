import React, { useEffect, useState } from "react";
import {
  Formik,
  useFormik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
} from "formik";
import * as Yup from "yup";
import $ from "jquery";
import {
  DEFAULT_HEADERS,
  METHOD_GET,
  METHOD_POST,
  url_changePassword,
  url_getModulePageListAll,
} from "../../components/utility/Config";
import { getFetch } from "../../components/utility/CallApi";
import { AlertCommit } from "../../components/utility/AlertBar";
import isObject from "lodash/isObject";
import ErrorFocus from "./ErrorFocus";

export default function FormResetPassword(props) {
  const [objEdit, setObjEdit] = useState(props.objResetPass);
  const [modulePageList, setModulePageList] = useState([]);

  useEffect(async () => {
    console.log(props);
    await setObjEdit(props.objResetPass);
    await getModulePageListAll();
    return () => {
      setObjEdit(null);
    };
  }, [props.objResetPass]);

  const getModulePageListAll = async () => {
    let body = {};
    let data = await getFetch(
      url_getModulePageListAll,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("data.RESULT_STATUS:::", data);
    if (data && data.RESULT_STATUS === "000") {
      setModulePageList(data.RESULT_DATA);
    } else if (data && data.RESULT_STATUS === "999") {
      return;
    }
  };

  const onSaveRoles = async (e) => {
    console.log("props:::", props);

    let body = {
      new_password: e.new_password,
    };

    console.log("body::::", body);
    let data = await getFetch(
      url_changePassword,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("data.RESULT_STATUS:::", data);
    if (data && data.RESULT_STATUS === "000") {
      await AlertCommit(true, "บันทึกเรียบร้อยแล้ว");
    } else if (data && data.RESULT_STATUS === "999") {
      await AlertCommit(false, "บันทึกไม่สำเร็จ");
    }
    await props.onClosePopup();
  };

  const onClosePopup = async () => {
    await props.onClosePopup();
  };

  return (
    <Formik
      initialValues={{
        user_login: objEdit ? objEdit.user_login : "",
        user_name: objEdit ? objEdit.user_name : "",
        new_password: objEdit ? objEdit.new_password : "",
        confirm_password: objEdit ? objEdit.confirm_password : "",
      }}
      onSubmit={async (values) => {
        console.log("values:::", values);
        // alert(JSON.stringify(values, null, 2));

        await onSaveRoles(values);
      }}
      validationSchema={Yup.object().shape({
        new_password: Yup.string().required("โปรดใส่ข้อมูล"),
        // confirm_password: Yup.string().required("โปรดใส่ข้อมูล"),
        confirm_password: Yup.string().when("new_password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("new_password")],
            "กรุณาใส่พาสเวิร์ดให้ตรงกัน"
          ).required("โปรดใส่ข้อมูล"),
        }),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;

        return (
          <form onSubmit={handleSubmit}>
            {console.log(values)}
            <div className="modal-header">
              <h5 className="modal-title" id="manageRoles">
                <b>Management Reset Password</b>
              </h5>
              <button
                id="btnClose"
                type="button"
                className="close"
                onClick={() => onClosePopup()}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <ErrorFocus />
            <div className="modal-body">
              <div className="container-fluid">
                <div className="form-group row">
                  <label
                    htmlFor="user_login"
                    className="col-5 col-form-label d-flex justify-content-end"
                  >
                    <b>User Login</b>
                  </label>
                  <input
                    type="text"
                    id="user_login"
                    value={values.user_login}
                    name="user_login"
                    disabled
                    className="col-7"
                  />
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="user_name"
                    className="col-5 col-form-label d-flex justify-content-end"
                  >
                    <b>ชื่อ</b>
                  </label>
                  <input
                    type="text"
                    id="user_name"
                    name="user_name"
                    value={values.user_name}
                    disabled
                    className="col-7"
                  />
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="new_password"
                    className="col-5 col-form-label d-flex justify-content-end"
                  >
                    <b>Reset Password</b>
                  </label>
                  <input
                    type="password"
                    id="new_password"
                    value={values.new_password}
                    name="new_password"
                    // className="col-7"
                    className={
                      errors.new_password && touched.new_password
                        ? "col-7 text-input error"
                        : "col-7 text-input"
                    }
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.new_password && touched.new_password && (
                    <div className="col-10 input-feedback-reset">
                      {errors.new_password}
                    </div>
                  )}
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="confirm_password"
                    className="col-5 col-form-label d-flex justify-content-end"
                  >
                    <b>Confirm Password</b>
                  </label>
                  <input
                    type="password"
                    id="confirm_password"
                    name="confirm_password"
                    value={values.confirm_password}
                    // className="col-7"
                    className={
                      errors.confirm_password && touched.confirm_password
                        ? "col-7 text-input error"
                        : "col-7 text-input"
                    }
                    placeholder="Confirm Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.confirm_password && touched.confirm_password && (
                    <div className="col-10 input-feedback-reset">
                      {errors.confirm_password}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                // onClick={handleReset}
                onClick={() => onClosePopup()}
              >
                ยกเลิก
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                บันทึก
              </button>
            </div>
            {/* </div>
              </div>
            </div> */}
          </form>
        );
      }}
    </Formik>
  );
}
