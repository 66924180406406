import React, { useContext } from "react";
import { Context } from "../../App";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import Module from "../../components/page/module/Module";
import Home from "../../components/page/home/Home";
import Footer from "../footer/Footer";
import Pages from "../../components/page/pages/Pages";
import Roles from "../../components/page/roles/Roles";
import Users from "../../components/page/users/Users";
import NotFound from "../../components/page/404NotFound/NotFound";
import Dashboard from "../../components/page/dashboard/Dashboard";
import Member from "../../components/page/member/Member";
import Reserve from "../../components/page/reserve/Reserve";
import Consent from "../../components/page/consent/Consent";
export default function Content() {
  const { sideBarActive, setSideBarActive } = useContext(Context);
  const { name } = useParams();
  const { url } = useRouteMatch();

  const renderSwitch = (param) => {
    switch (param) {
      case "home":
        return <Home />;
      case "module":
        return <Module />;
      case "pages":
        return <Pages />;
      case "roles":
        return <Roles />;
      case "users":
        return <Users />;
      case "dashboard":
        return <Dashboard />;
      case "member":
        return <Member />;
      case "reserve":
        return <Reserve />;
      case "consent":
        return <Consent />;
      default:
        return <NotFound />;
    }
  };

  return (
    <div
      style={{
        marginLeft: sideBarActive ? "250px" : "80px",
        transition: "all 0.3s",
        backgroundColor: "#FFF",
      }}
    >
      <div className="container-fluid" style={{ minHeight: "620px" }}>
        {/* {name === "module" && <Module />}
        {name === "home" && <Home />}
        {name === "pages" && <Pages />}
        {name === "roles" && <Roles />}
        {name === "users" && <Users />} */}
        {renderSwitch(name)}
      </div>
    </div>
  );
}
