import React, { useEffect, useState } from "react";
import axios from "axios";
import "./css/FormUsers.css";
import {
  Formik,
  useFormik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
} from "formik";
import * as Yup from "yup";
import $ from "jquery";
import {
  DEFAULT_HEADERS,
  METHOD_GET,
  METHOD_POST,
  url_newUser,
  url_updateUser,
  url_getRoleListAll,
  url_uploadProfileImage,
  BASE_URL,
} from "../../utility/Config";
import { getFetch } from "../../utility/CallApi";
import { AlertCommit } from "../../utility/AlertBar";
import isObject from "lodash/isObject";
import ErrorFocus from "./ErrorFocus";

export default function FormUsers(props) {
  const [objEdit, setObjEdit] = useState(props.objEditUsers);
  const [roleList, setRoleList] = useState([]);
  const [image, setImage] = useState(null);

  useEffect(async () => {
    await setObjEdit(props.objEditUsers);
    await getRoleListAll();

    return () => {
      setObjEdit(null);
    };
  }, [props.objEditUsers]);

  const getRoleListAll = async () => {
    let body = {
      module_code: "1",
      module_name: "1",
      module_icon: "1",
      module_order: "1",
      root_id: "1",
      is_active: "Y",
    };
    let data = await getFetch(
      url_getRoleListAll,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("data.RESULT_STATUS:::", data);
    if (data && data.RESULT_STATUS === "000") {
      setRoleList(data.RESULT_DATA);
    } else if (data && data.RESULT_STATUS === "999") {
      return;
    }
  };

  const onSaveUser = async (e) => {
    console.log("props:::", props);

    let filename = ""
    if(image && image !== ""){
      filename = await saveImage();
    }
    

    let newRoleList = "";
    e.role_list.map((p, key) => {
      if (key === 0) {
        newRoleList = p;
      } else {
        newRoleList = newRoleList + "," + p;
      }
    });
    let body = {
      user_login: e.user_login,
      user_name: e.user_name,
      user_password: e.user_password,
      user_img: filename,
      is_active: e.selectStatus,
      note1: e.note1,
      role_list: newRoleList,
    };

    console.log("body::::", body);
    let data = await getFetch(
      url_newUser,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("data.RESULT_STATUS:::", data);
    if (data && data.RESULT_STATUS === "000") {
      await AlertCommit(true, "บันทึกเรียบร้อยแล้ว");
    } else if (data && data.RESULT_STATUS === "999") {
      await AlertCommit(false, "บันทึกไม่สำเร็จ");
    }
    await props.onClosePopup();
  };

  const onUpdateUser = async (e) => {
    console.log("onUpdateUser:::", props);

    // let filename = await saveImage();

    let filename = ""
    if(image && image !== ""){
      filename = await saveImage();
    }else{
      filename = objEdit.user_img
    }
    // if(objEdit.user_img && objEdit.user_img !== ""){
      
    // }

    let newRoleList = "";
    e.role_list.map((p, key) => {
      if (key === 0) {
        newRoleList = p;
      } else {
        newRoleList = newRoleList + "," + p;
      }
    });

    let body = {
      user_login: e.user_login,
      user_name: e.user_name,
      user_password: e.user_password,
      user_img: filename,
      is_active: e.selectStatus,
      note1: e.note1,
      role_list: newRoleList,
      update_user_id: objEdit.user_id
    };

    console.log("body::::", body);
    let data = await getFetch(
      url_updateUser,
      METHOD_POST,
      DEFAULT_HEADERS,
      body,
      null
    );
    console.log("data.RESULT_STATUS:::", data);
    if (data && data.RESULT_STATUS === "000") {
      await AlertCommit(true, "บันทึกเรียบร้อยแล้ว");
    } else if (data && data.RESULT_STATUS === "999") {
      await AlertCommit(false, "บันทึกไม่สำเร็จ");
    }
    await props.onClosePopup();
  };

  const onClosePopup = async () => {
    await props.onClosePopup();
  };

  const handleImageLoad = async (e) => {
    // file: URL.createObjectURL(e.target.files[0])
    await setImage(e.target.files[0]);
  };

  const saveImage = async () => {

    let fileName = "";
    var formData = new FormData();
    formData.append("image", image);
    await axios.post(url_uploadProfileImage, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    }).then((res) => res.data)
    .then(
      (data) =>  {
      if (data && data.RESULT_STATUS === "000") {
        let objRet = data.RESULT_DATA[0];
        fileName = objRet.filename;
      }
    });
    
    return fileName;
  };

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  return (
    <Formik
      initialValues={{
        user_login: objEdit ? objEdit.user_login : "",
        user_name: objEdit ? objEdit.user_name : "",
        user_password: objEdit ? objEdit.user_password : "",
        confirm_password: objEdit ? objEdit.user_password : "",
        user_image: objEdit ? objEdit.user_img : null,
        note1: objEdit ? objEdit.note1 : "",
        role_list: objEdit ? objEdit.role_list : [],
        selectStatus: objEdit ? objEdit.is_active : "Y",
      }}
      onSubmit={async (values) => {
        console.log("values:::", values);

        // alert(JSON.stringify(values, null, 2));
        if (props.openPopupUpdateForm) {
          console.log("update");
          await onUpdateUser(values);
        } else {
          console.log("save");
          await onSaveUser(values);
        }
      }}
      validationSchema={Yup.object().shape({
        user_login: Yup.string().required("โปรดใส่ข้อมูล"),
        user_name: Yup.string().required("โปรดใส่ข้อมูล"),
        user_password: Yup.string().required("โปรดใส่ข้อมูล"),
        // confirm_password: Yup.string().required("โปรดใส่ข้อมูล"),
        confirm_password: Yup.string().when("user_password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("user_password")],
            "กรุณาใส่พาสเวิร์ดให้ตรงกัน"
          ).required("โปรดใส่ข้อมูล"),
        }),
        role_list: Yup.array().min(1).required(),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
        } = props;

        return (
          <form onSubmit={handleSubmit}>
            <div className="modal-header">
              <h5 className="modal-title" id="manageUsers">
                <b>Management Users</b>
              </h5>
              <button
                id="btnClose"
                type="button"
                className="close"
                onClick={() => onClosePopup()}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <ErrorFocus />
            <div className="modal-body">
              <div className="container-fluid">
                <div className="form-group row">
                  <label
                    htmlFor="user_login"
                    className="col-2 col-form-label d-flex justify-content-end"
                  >
                    <b>User Login</b>
                  </label>
                  <input
                    type="text"
                    id="user_login"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.user_login}
                    maxLength="6"
                    className={
                      errors.user_login && touched.user_login
                        ? "col-4 text-input error"
                        : "col-4 text-input"
                    }
                    name="user_login"
                    placeholder="User Login"
                  />
                  <label
                    htmlFor="userName"
                    className="col-2 col-form-label d-flex justify-content-end"
                  >
                    <b>User Name</b>
                  </label>
                  <input
                    type="text"
                    id="user_name"
                    name="user_name"
                    placeholder="NAME"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.user_name}
                    className={
                      errors.user_name && touched.user_name
                        ? "col-4 text-input error"
                        : "col-4 text-input"
                    }
                  />
                </div>
                <div className="form-group row">
                  {errors.user_login && touched.user_login && (
                    <div className="col-6 input-feedback-user">
                      {errors.user_login}
                    </div>
                  )}
                  {errors.user_name && touched.user_name && (
                    <div
                      className="offset-6 col-6 input-feedback-user"
                      style={{ position: "absolute", paddingLeft: "15%" }}
                    >
                      {errors.user_name}
                    </div>
                  )}
                </div>
                {!objEdit && (
                  <>
                    <div className="form-group row">
                      <label
                        htmlFor="user_password"
                        className="col-2 col-form-label d-flex justify-content-end"
                      >
                        <b>Password</b>
                      </label>
                      <input
                        type="password"
                        id="user_password"
                        name="user_password"
                        value={values.user_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // placeholder="fa fa-circle-o"
                        className={
                          errors.user_password && touched.user_password
                            ? "col-4 text-input error"
                            : "col-4 text-input"
                        }
                      />
                      <label
                        htmlFor="confirm_password"
                        className="col-2 col-form-label d-flex justify-content-end"
                      >
                        <b>Confirm Password</b>
                      </label>
                      <input
                        type="password"
                        id="confirm_password"
                        name="confirm_password"
                        value={values.confirm_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // placeholder="fa fa-circle-o"
                        className={
                          errors.confirm_password && touched.confirm_password
                            ? "col-4 text-input error"
                            : "col-4 text-input"
                        }
                      />
                    </div>
                    <div className="form-group row">
                      {errors.user_password && touched.user_password && (
                        <div className="col-6 input-feedback-user">
                          {errors.user_password}
                        </div>
                      )}
                      {errors.confirm_password && touched.confirm_password && (
                        <div
                          className="offset-6 col-6 input-feedback-user"
                          style={{ position: "absolute", paddingLeft: "15%" }}
                        >
                          {errors.confirm_password}
                        </div>
                      )}
                    </div>
                  </>
                )}

                <div className="form-group row">
                  <label
                    htmlFor="icon"
                    className="col-2 col-form-label d-flex justify-content-end"
                  >
                    <b>Status</b>
                  </label>
                  <select
                    id="selectStatus"
                    name="selectStatus"
                    value={values.selectStatus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.selectStatus && touched.selectStatus
                        ? "form-control col-4 text-input error"
                        : "form-control col-4 text-input"
                    }
                  >
                    <option value={"Y"}>ใช้งาน</option>
                    <option value={"N"}>ไม่ใช้งาน</option>
                  </select>
                  <label
                    htmlFor="uploadImage"
                    className="col-2 col-form-label d-flex justify-content-end"
                  >
                    <b>Image</b>
                  </label>
                  <input
                    type="file"
                    id="user_image"
                    name="user_image"
                    // value={values.user_image}
                    // onChange={handleChange}
                    // onChange={(event) => {
                    //   setFieldValue("user_image", event.currentTarget.files[0]);
                    // }}
                    onChange={(e) => handleImageLoad(e)}
                    onClick={(e) => handleClick(e)}
                    onBlur={handleBlur}
                    // className={
                    //   errors.confirm_password && touched.confirm_password
                    //     ? "form-control-sm col-3 text-input error"
                    //     : "form-control-sm col-3 text-input"
                    // }
                    className="form-control-sm col-3 text-input"
                  />
                  <div className="col-1">
                    <img
                      // src={objEdit && objEdit.user_img ? "/img/profileImage/"+objEdit.user_img : "/img/avatar.jpg"}
                      src={objEdit && objEdit.user_img ? BASE_URL+objEdit.user_img : "/img/avatar.jpg"}
                      alt="Avatar"
                      style={{
                        borderRadius: "50%",
                        width: "65px",
                        top: "-15px",
                        position: "absolute",
                      }}
                    ></img>
                  </div>
                </div>
                <div className="form-group row">
                  {errors.selectStatus && touched.selectStatus && (
                    <div className="col-6 input-feedback-user">
                      {errors.selectStatus}
                    </div>
                  )}
                  {/* {errors.confirm_password && touched.confirm_password && (
                    <div className="offset-6 col-6 input-feedback-user">
                      {errors.confirm_password}
                    </div>
                  )} */}
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="note1"
                    className="col-2 col-form-label d-flex justify-content-end"
                  >
                    <b>Description</b>
                  </label>
                  <input
                    type="text"
                    id="note1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.note1}
                    className={
                      errors.note1 && touched.note1
                        ? "col-10 text-input error"
                        : "col-10 text-input"
                    }
                    name="note1"
                  />
                </div>
              </div>
              <div className="box box-info">
                <div className="box-header">Select Roles</div>
                <div className="box-body">
                  <div className="container-fluid">
                    <FieldArray
                      name="role_list"
                      render={(arrayHelpers) => (
                        <div className="form-group row">
                          {roleList &&
                            roleList.length > 0 &&
                            roleList.map((role, key2) => (
                              <>
                                <div
                                  className="col-3 form-inline form-check"
                                  key={role.role_id}
                                >
                                  <input
                                    name="role_list"
                                    type="checkbox"
                                    value={role.role_id}
                                    id={role.role_id}
                                    checked={values.role_list.includes(
                                      role.role_id
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        arrayHelpers.push(role.role_id);
                                      } else {
                                        const idx = values.role_list.indexOf(
                                          role.role_id
                                        );
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                    className={
                                      "form-control col-2 form-check-input " +
                                      (errors.role_list && touched.role_list
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    for={role.role_id}
                                  >
                                    {role.role_name}
                                  </label>
                                </div>
                                {roleList.length > 4 && (key2 + 1) % 4 == 0 && (
                                  <div className="form-group col-12"></div>
                                )}
                              </>
                            ))}
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                // onClick={handleReset}
                onClick={() => onClosePopup()}
              >
                ยกเลิก
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                บันทึก
              </button>
            </div>
            {/* </div>
              </div>
            </div> */}
          </form>
        );
      }}
    </Formik>
  );
}
